import * as S from './styles';

type TagProps = {
  free?: boolean;
  physical?: boolean;
  specificRoute?: boolean;
  testRide?: boolean;
  customMargin?: string;
};

const Tag = ({
  free,
  physical,
  specificRoute,
  testRide,
  customMargin
}: TagProps): JSX.Element => {
  return (
    <>
      {free && (
        <S.Container free={free} customMargin={customMargin}>
          Gratuito
        </S.Container>
      )}

      <S.Container physical={physical} customMargin={customMargin}>
        {physical ? 'Presencial' : 'Virtual'}
      </S.Container>

      {testRide && (
        <S.Container testRide={testRide} customMargin={customMargin}>
          Test Ride
        </S.Container>
      )}

      {specificRoute && !physical && (
        <S.Container specificRoute={specificRoute} customMargin={customMargin}>
          Rota Local
        </S.Container>
      )}
    </>
  );
};

export default Tag;
