import styled, { keyframes } from 'styled-components';

const slide = keyframes`
  from{
    left: 0
  }
  to{
    left: 100%
  }
`;

export const Container = styled.div<{ fitContainer?: boolean }>`
  height: ${({ fitContainer }) => (fitContainer ? '100%' : '100vh')};
  width: ${({ fitContainer }) => (fitContainer ? '100%' : '100vw')};
  display: grid;
  place-items: center;
`;

export const SkeletonLoader = styled.div`
  background: ${({ theme }) => `${theme.colors.blackText}12`};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 1.2rem;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 25%;
    left: 0;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => ` 0px 0px 15px 15px ${theme.colors.white}`};
    animation: ${slide} 0.5s ease-in infinite alternate;
  }
`;
