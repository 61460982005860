import styled from 'styled-components';

export const Container = styled.div<{
  free?: boolean;
  physical?: boolean;
  testRide?: boolean;
  specificRoute?: boolean;
  customMargin?: string;
}>`
  padding: 0.2rem 0.8rem;
  border-radius: 10rem;
  font-size: 1.1rem;
  line-height: 2.1rem;
  max-height: 3rem;
  font-weight: 600;
  color: #fff;
  margin: ${({ customMargin }) =>
    customMargin ? customMargin : '0 0.8rem 0.8rem;'};
  background: ${({ free, physical, testRide, specificRoute, theme }) => {
    if (free) return theme.colors.greenLime;
    if (physical) return theme.colors.redAccent;
    if (testRide) return theme.colors.purple;
    if (specificRoute) return `${theme.colors.red}`;
    return `${theme.colors.yellow}`;
  }};
`;
