import React from 'react';
import { IDefaultTheme } from '@source/styles/themes';
import { useTheme } from 'styled-components';
import * as S from './styles';
import CircularProgress from '@mui/material/CircularProgress';


type LoaderTypeType = 'spin' | 'skeleton';

type SuspenseLoaderProps = {
  loading: any;
  children?: React.ReactNode;
  fitContainer?: boolean;
  color?: string;
  style?: React.CSSProperties;
  loaderType?: LoaderTypeType;
  size?: number;
};

const SuspenseLoader: React.FC<SuspenseLoaderProps> = ({
  loading,
  children,
  fitContainer,
  color,
  style,
  loaderType = 'spin',
  size = 24
}): JSX.Element => {
  const theme = useTheme() as IDefaultTheme;

  const loaderTypes: Record<LoaderTypeType, JSX.Element> = {
    spin: (
      <CircularProgress
        data-testid="circular-progress"
        size={size}
        style={{ color: color || theme.colors.blue }}
      />
    ),
    skeleton: <S.SkeletonLoader data-testid="skeleton-progress" />
  };

  if (!!loading) {
    return (
      <S.Container
        data-testid="circular-progress-container"
        fitContainer={fitContainer}
        style={style}
      >
        {loaderTypes[loaderType]}
      </S.Container>
    );
  }

  return <>{children}</>;
};

export default SuspenseLoader;
