import { Box } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import type {
  Challenge,
  ChallengeWithCompleted
} from '@source/graphql/autogenerate/schemas';
import { formatDateShortNoTimezone } from '@source/utils/formatDate';
import Tag from '@components/ChallengeCardTag';
import * as S from './styles';
import Link from 'next/link';
import { isFuture, isPast } from 'date-fns';
import SuspenseLoader from '@source/components/SuspenseLoader';
import Image from 'next/legacy/image';
import getPixelRatio from '@source/utils/getPixelRatio';
import { useState } from 'react';

type ChallengeCardProps = {
  challenge: Partial<ChallengeWithCompleted> | Challenge;
  company_username?: string;
  firstItem?: boolean;
  isLastItem?: boolean;
};

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  company_username,
  firstItem,
  isLastItem
}): JSX.Element => {
  // Width da imagem do carrossel para renderização dinâmica da altura
  const [carouselImageWidth, setCarouselImageWidth] = useState<number>(0);

  const isPhysicalEvent = challenge.physical_event;
  const isPaid = challenge.configuration?.is_paid;
  const testRide = challenge.challenge_format_slug === 'test_ride';
  const specificRoute = challenge.challenge_type === 'specific-route';

  const challengeDate = () => {
    if (isFuture(new Date(challenge.start_date_registration))) {
      return 'Abrem em breve';
    }

    if (isPast(new Date(challenge.end_date_registration))) {
      return 'Fechadas';
    }

    return 'Abertas';
  };

  const isCycleRoute = challenge.challenge_format_slug === 'cycle_routes';
  const challengeUrl = `/challenges/${
    challenge.company?.profile?.username || company_username
  }/${challenge.slug_name}-${challenge.short_id}`;
  const cycleRouteUrl = `/routes/${challenge.slug_name}-${challenge.short_id}`;
  const redirectUrl = isCycleRoute ? cycleRouteUrl : challengeUrl;

  return (
    <SuspenseLoader loading={!challenge}>
      <S.Card
        firstItem={firstItem}
        isLastItem={isLastItem}
        // Cálculo de width da imagem do carrossel
        ref={ref => ref && setCarouselImageWidth(ref.clientWidth)}
      >
        <Link legacyBehavior passHref href={redirectUrl}>
          <a>
            <CardActionArea>
              <Box sx={{ position: 'relative', background: '#fff' }}>
                <S.MaterialCardMedia
                  firstItem={firstItem}
                  isLastItem={isLastItem}
                  carouselImageWidth={carouselImageWidth}
                >
                  <Image
                    src={`/${challenge.image_cover}?wdt=${getPixelRatio(
                      300
                    )}&hgt=${getPixelRatio(360)}`}
                    loader={({ src }) =>
                      `${process.env.NEXT_PUBLIC_CDN_URL}${src}`
                    }
                    layout="fill"
                    objectFit="cover"
                    alt={challenge?.name || ''}
                    title={challenge.name}
                  />
                </S.MaterialCardMedia>

                <CardMedia
                  sx={{
                    width: '5rem',
                    height: '5rem',
                    position: 'absolute',
                    bottom: '2.4rem',
                    left: '2.4rem'
                  }}
                >
                  {!isCycleRoute && (
                    <Image
                      src={`/${challenge.image_avatar}?wdt=${getPixelRatio(
                        50
                      )}&hgt=${getPixelRatio(50)}`}
                      loader={({ src }) =>
                        `${process.env.NEXT_PUBLIC_CDN_URL}${src}`
                      }
                      width={50}
                      height={50}
                      alt={challenge?.name || ''}
                      title={challenge.name}
                    />
                  )}
                </CardMedia>
              </Box>

              <S.CardContent>
                <S.TagBox>
                  <Tag
                    free={!isPaid}
                    physical={isPhysicalEvent}
                    specificRoute={specificRoute}
                    testRide={testRide}
                    customMargin="0.5rem 0.8rem 0 0"
                  />
                </S.TagBox>

                <S.EventName>{challenge.name}</S.EventName>

                {challenge?.start_date_registration &&
                  challenge?.end_date_registration && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box sx={{ marginRight: '2rem' }}>
                        <S.Text>Inscrições</S.Text>
                        <S.TextBold>{challengeDate()}</S.TextBold>
                      </Box>

                      <Box>
                        <S.Text>Data</S.Text>
                        <S.TextBold>
                          {formatDateShortNoTimezone(challenge.start_date)}
                        </S.TextBold>
                      </Box>
                    </Box>
                  )}
              </S.CardContent>
            </CardActionArea>
          </a>
        </Link>
      </S.Card>
    </SuspenseLoader>
  );
};

export default ChallengeCard;
