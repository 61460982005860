import styled from 'styled-components';
import media from 'styled-media-query';

export const Card = styled.div<{ firstItem?: boolean; isLastItem?: boolean }>`
  border-radius: 10px;
  margin-left: 1.6rem;
  margin-right: ${({ isLastItem }) => (isLastItem ? '1.6rem' : '0')};

  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;

  transition: all 300ms ease-in-out;
  transform: scale(1);
  opacity: 1;

  @media (min-width: 900px) {
    margin-left: ${({ firstItem }) => (firstItem ? '0' : '1.6rem')};
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }

  border: ${({ theme }) => `0.08rem solid ${theme.colors.grayBorder}`};
  border-radius: 1.6rem;
  box-shadow: none;
`;

export const TagBox = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const EventName = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackText};
  height: 4.5rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  z-index: 1;

  ${media.greaterThan('medium')`
  height: 5rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  `};
`;

export const Text = styled.p`
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.gray2};

  ${media.greaterThan('medium')`
    font-size: 1.3rem;
  `}
`;

export const TextBold = styled.p`
  font-weight: bold;
  font-size: 1.2rem;

  ${media.greaterThan('medium')`
    font-size: 1.3rem;
  `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem 1.6rem 1.6rem;
`;

export const MaterialCardMedia = styled.div<{
  firstItem?: boolean;
  isLastItem?: boolean;
  carouselImageWidth: number;
}>`
  width: 100%;

  height: ${({ isLastItem, carouselImageWidth }) => {
    // subtracts the first card extra width difference. 246 pixels is the placeholder before the width state is set
    // the height:width ratio should always be 1.2
    if (isLastItem) {
      return `${((carouselImageWidth || 246) + 16) * 1.2}px`;
    }

    return `${(carouselImageWidth || 246) * 1.2}px`;
  }};

  @media (min-width: 900px) {
    height: ${({ firstItem, carouselImageWidth }) => {
      // subtracts the first card extra width difference. 246 pixels is the placeholder before the width state is set
      // the height:width ratio should always be 1.2

      if (firstItem) {
        return `${((carouselImageWidth || 246) - 16) * 1.2}px`;
      }

      return `${(carouselImageWidth || 246) * 1.2}px`;
    }};
  }
`;
